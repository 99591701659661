<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">RefStats</a>
      <button class="navbar-toggler"
              type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <Dropdown v-model="selectedSeason"
                      :options="seasons" optionLabel="name" placeholder="Select A Season"
                      v-on:change="getGameweeks"
            />
          </li>
          <li class="nav-item" v-if="selectedSeason!==null">
            <Dropdown v-model="selectedGameweek"
                      :options="gameweeks" optionLabel="name" placeholder="Select A Gameweek"
                      v-on:change="getMatches"
            />
          </li>
        </ul>
        <div class="btn-group"
             v-if="this.$store.state.token==='null' || this.$store.state.token===''">
          <button type="button" class="btn btn-secondary"
                  v-on:click="this.$router.push('/refereeRanking');">Referee Ranking</button>
          <button type="button" class="btn btn-secondary"
                  v-if="this.$store.state.token==='null' || this.$store.state.token===''"
                  v-on:click="this.$router.push('/login');">LOGIN</button>
          <button type="button" class="btn btn-secondary"
                  v-if="this.$store.state.token==='null' || this.$store.state.token===''"
                  v-on:click="this.$router.push('/login');">Register</button>
        </div>
        <div class="btn-group"
             v-if="this.$store.state.token!=='null' && this.$store.state.token!==''">
          <button type="button" class="btn btn-secondary"
                  v-on:click="this.$router.push('/refereeRanking');">Referee Ranking</button>
          <button type="button" class="btn btn-secondary"
                  v-on:click="this.$router.push('/profile/'+this.$store.state.user);">
            {{this.$store.state.user}}</button>
          <button type="button" class="btn btn-secondary"
                  v-on:click="logout">LOGOUT</button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Dropdown from 'primevue/dropdown';

export default {
  name: 'HomeNavigationBar',
  components: {
    Dropdown,
  },
  props: {
    seasons: {
      type: Array,
      required: true,
    },
    gameweeks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedSeason: null,
      selectedGameweek: null,
    };
  },
  methods: {
    getGameweeks() {
      this.$emit('get-gameweeks', this.selectedSeason);
    },
    getMatches() {
      this.$emit('get-matches', this.selectedGameweek);
    },
    logout() {
      window.location.reload();
      this.$store.commit('setToken', null);
      this.$store.commit('setUser', null);
      this.$store.commit('setRefreshToken', null);
    },
  },
};
</script>

<style scoped>
.nav-item {
  padding-right: 5pt;
}
.navbar-brand {
  font-family: 'Uni Sans Heavy', serif;
  font-size: 1.5em;
}
:deep(.p-dropdown-label) {
  font-family: 'Uni Sans Heavy', serif;
}
</style>

<template>
  <Loader v-if="!isLoaded" />
  <div v-if="isLoaded">
    <NavigationBar
        :seasons="seasons"
        :gameweeks="gameweeks"
        @get-gameweeks="getGameweeksEvent($event)"
        @get-matches="getMatchesEvent($event)"
    />
    <div class="container" v-if="matches !== null">
      <Dashboard
          :matches="matches"
      />
    </div>
  </div>
</template>

<script>
import Dashboard from '@/components/Home/Dashboard.vue';
import NavigationBar from '@/components/Home/HomeNavigationBar.vue';
import Loader from '@/components/Loader.vue';

export default {
  name: 'Home',
  components: {
    NavigationBar,
    Dashboard,
    Loader,
  },
  data() {
    return {
      selectedSeason: null,
      selectedGameweek: null,
      seasons: [],
      gameweeks: [],
      matches: [],
      isLoaded: false,
    };
  },
  methods: {
    async getLatestGameweek() {
      await this.axios.get(`https://${this.$store.state.ip}/get-latest-gameweek`)
        .then((response) => {
          this.selectedGameweek = response.data.gameweek_id;
          this.getMatches();
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    async getSeasons() {
      await this.axios.get(`https://${this.$store.state.ip}/get-all-seasons`)
        .then((response) => {
          this.seasons = response.data;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    async getGameweeksEvent($event) {
      // check if season is object
      this.selectedSeason = $event;
      let season = this.selectedSeason;
      if (typeof this.selectedSeason === 'object') {
        season = this.selectedSeason.value;
      }
      await this.axios.get(`https://${this.$store.state.ip}/get-all-gameweeks/${season}`)
        .then((response) => {
          this.gameweeks = response.data;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    async getMatchesEvent($event) {
      // check if gameweek is object
      this.selectedGameweek = $event;
      let gameweek = this.selectedGameweek;
      if (typeof this.selectedGameweek === 'object') {
        gameweek = this.selectedGameweek.value;
      }
      await this.axios.get(`https://${this.$store.state.ip}/get-all-matches/${gameweek}`)
        .then((response) => {
          this.matches = response.data;
          this.isLoaded = true;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    async getGameweeks() {
      // check if season is object
      let season = this.selectedSeason;
      if (typeof this.selectedSeason === 'object') {
        season = this.selectedSeason.value;
      }
      await this.axios.get(`https://${this.$store.state.ip}/get-all-gameweeks/${season}`)
        .then((response) => {
          this.gameweeks = response.data;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    async getMatches() {
      // check if gameweek is object
      let gameweek = this.selectedGameweek;
      if (typeof this.selectedGameweek === 'object') {
        gameweek = this.selectedGameweek.value;
      }
      await this.axios.get(`https://${this.$store.state.ip}/get-all-matches/${gameweek}`)
        .then((response) => {
          this.matches = response.data;
          this.isLoaded = true;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
  },
  created() {
    this.getLatestGameweek();
    this.getSeasons();
    this.getGameweeks();
  },
};
</script>

<style scoped>

</style>

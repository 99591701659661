<template>
  <div>
    <ul class="list-group list-group-flush" id="matchdays">
      <li  class="card" v-for="match in matches" :key="match">
        <div class="card-header">
          <div class="h4">{{ match[0].kickoff_time }}</div>
        </div>
        <div class="card-body">
          <ul class="list-group" v-for="item in match" :key="item" >
            <li class="list-group-item" v-on:click="goToMatch(item)">
              <div class="row row-cols-3 justify-content-center">
                <div class="col-sm-4">
                  <div class="h5">{{item.home_team_name}}</div>
                </div>
                <div class="col-sm-1">
                  <div class="h5" v-if="item.home_team_score !== null">
                    {{item.home_team_score}} : {{item.away_team_score}}
                  </div>
                  <div class="h5" v-if="item.home_team_score === null">
                    N : A
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="h5">{{item.away_team_name}}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </li >
    </ul>
  </div>
</template>

<script>

import { useToast } from 'vue-toastification';

export default {
  name: 'Dashboard',
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  props: {
    matches: {
      type: Array,
      required: true,
    },
  },
  methods: {
    goToMatch(match) {
      if (match.home_team_score === null) {
        this.toast.info('Data Not Available Yet', {
          autoHideDelay: 5000,
          closeOnClick: true,
        });
      } else {
        this.$router.push(`/match/${match.match_id}`);
      }
    },
  },
};
</script>

<style scoped>
#matchdays {
  margin-bottom: 1em;
}
.card {
  margin-top: 1em;
}
.card-header {
  font-weight: bolder;
  background-color: #272729;
}
.list-group {
  border-color: #222222;
}
.list-group-item {
  font-size: 1.4em;
  border-right-color: #303030;
  border-left-color: #303030;
}
.list-group-item:hover {
  color: grey;
}
.col {
  border: 1px solid black;
}
</style>
